/* All free solid fontawesome icons -> https://fontawesome.com/icons?d=gallery&s=brands&m=free */

import {
  faFacebook,
  faFacebookF,
  faGithub,
  faGoogle,
  faMarkdown,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

export const fontAwesomeBrandsIcons = {
  faGithub,
  faFacebook,
  faFacebookF,
  faGoogle,
  faMarkdown,
  faTwitter,
};
