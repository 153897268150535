/* All free solid fontawesome icons -> https://fontawesome.com/icons?d=gallery&s=solid&m=free */



import {
  faAngleDown,
  faAngleRight,
  faArrowCircleDown,
  faArrowCircleRight,
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faBackward,
  faBars,
  faBookOpen,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faChartArea,
  faChartBar,
  faChartPie,
  faChevronDown,
  faChevronUp,
  faCircle,
  faCog,
  faColumns,
  faCompressArrowsAlt,
  faDownload,
  faExpandArrowsAlt,
  faFileVideo,
  faFilm,
  faFolder,
  faFont,
  faForward,
  faHandPointer,
  faLayerGroup,
  faMapMarker,
  faMinus,
  faParachuteBox,
  faPause,
  faPencilAlt,
  faPlay,
  faPlus,
  faQuestion,
  faReply,
  faScroll,
  faSearch,
  faSnowflake,
  faSnowman,
  faSpinner,
  faStepBackward,
  faStepForward,
  faTable,
  faTachometerAlt,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faToilet,
  faUser,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';

export const fontAwesomeSolidIcons = {
  faAngleDown,
  faAngleRight,
  faArrowCircleDown,
  faArrowCircleRight,
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faBackward,
  faBars,
  faBookOpen,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faChartArea,
  faChartBar,
  faChartPie,
  faChevronDown,
  faChevronUp,
  faCircle,
  faCog,
  faColumns,
  faCompressArrowsAlt,
  faDownload,
  faExpandArrowsAlt,
  faFileVideo,
  faFilm,
  faFolder,
  faFont,
  faForward,
  faHandPointer,
  faLayerGroup,
  faMapMarker,
  faMinus,
  faParachuteBox,
  faPause,
  faPencilAlt,
  faPlay,
  faPlus,
  faQuestion,
  faReply,
  faScroll,
  faSearch,
  faSnowflake,
  faSnowman,
  faSpinner,
  faStepBackward,
  faStepForward,
  faTable,
  faTachometerAlt,
  faThumbsUp,
  faThumbsDown,
  faTimes,
  faTimesCircle,
  faToilet,
  faUser,
  faVideo,
};
